import { render, staticRenderFns } from "./handleModuleDetail copy.vue?vue&type=template&id=3c5b8d6f&scoped=true"
import script from "./handleModuleDetail copy.vue?vue&type=script&lang=js"
export * from "./handleModuleDetail copy.vue?vue&type=script&lang=js"
import style0 from "./handleModuleDetail copy.vue?vue&type=style&index=0&id=3c5b8d6f&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c5b8d6f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/www/jenkins_workspase/workspace/html-sunyun-saas-erp-application-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3c5b8d6f')) {
      api.createRecord('3c5b8d6f', component.options)
    } else {
      api.reload('3c5b8d6f', component.options)
    }
    module.hot.accept("./handleModuleDetail copy.vue?vue&type=template&id=3c5b8d6f&scoped=true", function () {
      api.rerender('3c5b8d6f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/tenant/product/module/components/handleModuleDetail copy.vue"
export default component.exports